import {
  OffersByCategory,
  RentalOffer,
} from '@silvertours/common-landingpages-view';
import { Carousel, SerpOffer, analytics } from '@silvertours/front-entities';
import { useCallback, useMemo } from 'react';
import { Heading2, Theme } from '@silvertours/front-shared';
import { PageAttributeType } from '@silvertours/back-domain-page';
import { useMedia } from 'react-use';
import { Container } from './SerpOffers.styles';
import { useSubmitInitialSearch } from '../SearchFormLegacy';

export type Props = {
  content: OffersByCategory;
  translations: {
    airConditioning: string;
    prevText: string;
    nextText: string;
    disclaimertext: string;
    pricePrefix: string;
    priceSuffix: string;
    serpTitle: string;
    transmissionManual: string;
    transmissionAutomatic: string;
    searchButtonText: string;
    carTypes: {
      [Key: string]: string;
      compact: string;
      convertible: string;
      medium: string;
      mini: string;
      premium: string;
      small: string;
      sport: string;
      station: string;
      suv: string;
      transporter: string;
      upper: string;
      van: string;
    };
  };
};

const MAX_OFFERS = 12;

const SerpOffers = ({
  content,
  translations: {
    airConditioning,
    carTypes,
    prevText,
    nextText,
    disclaimertext,
    pricePrefix,
    priceSuffix,
    serpTitle,
    transmissionAutomatic,
    transmissionManual,
    searchButtonText,
  },
}: Props) => {
  const offers = useMemo(() => {
    const result: RentalOffer[] = [];
    Object.values(content).forEach(categoryOffers => {
      categoryOffers.offers.forEach(offer => {
        // skip offers with car_image.php usage for now,
        // as there is a chance that images are not available
        // anymore after a certain time
        if (
          (offer.attributes !== null || offer.category === 'transporter') &&
          !/car_image\.php/.test(offer.originalImage)
        ) {
          result.push(offer);
        }
      });
    });
    return result.sort((a, b) => a.price - b.price).slice(0, MAX_OFFERS);
  }, [content]);

  const { makeSearchHandler } = useSubmitInitialSearch();
  const onSlideChange = (direction: 'forward' | 'backward') => {
    analytics.gtm.trackOffersSliderButtonClick(direction);
  };

  const onSlideDrag = useCallback((offset: number) => {
    analytics.gtm.trackOffersSliderDragged(offset);
  }, []);

  const onMouseWheel = useCallback((offset: number) => {
    analytics.gtm.trackOffersSliderMouseWheel(offset);
  }, []);

  const CarouselContainer = Container;

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    true,
  );

  const visibleItemsAmount = isMobile ? 1 : 3;

  if (!offers.length) {
    return null;
  }

  return (
    <div>
      <Heading2>{serpTitle}</Heading2>
      <CarouselContainer
        as={Carousel}
        hasItemsToReveal={offers.length > visibleItemsAmount}
        showSlideBackground={false}
        shouldLoop
        snap
        prevText={prevText}
        nextText={nextText}
        captionText={disclaimertext}
        onSlideChange={onSlideChange}
        onSlideDrag={onSlideDrag}
        onMouseWheel={onMouseWheel}
      >
        {offers.map(offer => {
          const handleClick = () => {
            const handleSearch = makeSearchHandler({
              cityCode: offer.cityCode,
              cityName: offer.city,
              query: offer.city,
              displayName: offer.city,
              type: PageAttributeType.City,
            });
            if (handleSearch) {
              analytics.gtm.trackOffersSliderOffferClick(offer.id ?? '');
              handleSearch(offer.city, {
                depDate: offer.departureDate.replace(' ', 'T'),
                destDate: offer.destinationDate.replace(' ', 'T'),
              });
            }
          };

          let transmission = '';
          let seats = 2;
          let doors = '';
          let aircon = false;

          if (offer.attributes !== null) {
            transmission = offer.attributes!.transmission;
            seats = offer.attributes!.seats;
            doors = offer.attributes!.doors;
            aircon = offer.attributes!.airConditioning;
          }

          return (
            <SerpOffer
              key={offer.id}
              onClick={handleClick}
              image={offer.originalImage}
              carCategory={carTypes[offer.category]}
              carName={offer.carName}
              transmission={transmission}
              seats={seats}
              doors={doors}
              airConditioning={aircon}
              city={offer.city}
              price={offer.price}
              currency="EUR"
              translations={{
                airConditioning,
                transmissionAutomatic,
                transmissionManual,
                pricePrefix,
                priceSuffix,
                searchButtonText,
              }}
            />
          );
        })}
      </CarouselContainer>
    </div>
  );
};

export { SerpOffers as SerpOffersModule };
