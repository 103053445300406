import styled from '@emotion/styled';
import Checked from '@engineering/icons/checked';
import { Heading2, Ui } from '@silvertours/front-shared';
import { AppLogo } from './AppLogo';

export const IllustrationWrapper = styled.div`
  grid-area: illustration;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  ${({ theme }) => theme.mq('md')} {
    width: fit-content;
    position: absolute;
    right: 0;
    top: 15rem;
  }

  ${({ theme }) => theme.mq('lg')} {
    position: static;
    margin: -16px auto;
  }
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-areas:
    'content'
    'illustration';
  gap: ${({ theme }) => theme.spacing[60]};

  ${({ theme }) => theme.mq('md')} {
    grid-template-areas: 'content illustration';
    grid-template-columns: 1fr auto;
    align-items: flex-start;
  }
`;

export const ContentSection = styled.div`
  grid-area: content;
  gap: ${({ theme }) => theme.spacing[50]};

  ${({ theme }) => theme.mq('sm')} {
    grid-template-areas: 'text illustration';
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  ${({ theme }) => theme.mq('md')} {
    grid-template-areas:
      'text'
      'illustration';
    grid-template-columns: 1fr;
  }
`;

export const PromoContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;

  ${({ theme }) => theme.mq('md')} {
    padding: ${({ theme }) => theme.spacing[60]} 0 0 0;
  }
`;

export const IntroSection = styled.div`
  display: grid;
  grid-template-areas:
    'logo title'
    'logo subtitle';
  grid-template-columns: auto 2fr;
  gap: ${({ theme }) => theme.spacing[50]};
  margin: ${({ theme }) => theme.spacing[60]} 0 0;
`;

export const Logo = styled(AppLogo)`
  grid-area: logo;
  height: 64px;
  width: auto;
  filter: drop-shadow(0 1px 8px rgb(0 0 0 / 20%));
`;

export const Title = styled(Heading2)`
  grid-area: title;
  padding: 0 0 ${({ theme }) => theme.spacing[20]};
  margin: 0;
  color: ${({ theme }) => theme.color.white};
`;

export const Subtitle = styled(Heading2)`
  grid-area: subtitle;
  margin: 8px 0 0;
  color: ${({ theme }) => theme.color.white};
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${({ theme }) => theme.spacing[60]} 0 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[50]};

  ${({ theme }) => theme.mq('md')} {
    margin: ${({ theme }) => theme.spacing[60]} 0 150px;
  }

  ${({ theme }) => theme.mq('lg')} {
    margin: ${({ theme }) => theme.spacing[60]} 0 0;
  }
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[30]};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.color.white};

  ${({ theme }) => theme.mq('lg')} {
    padding-right: ${({ theme }) => theme.spacing[40]};
  }
`;

export const FeatureDot = styled(Checked)`
  width: ${({ theme }) => theme.spacing[50]};
  height: ${({ theme }) => theme.spacing[50]};
  fill: currentcolor;
`;

export const StoreLinksContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[40]};
  flex-wrap: wrap;
  width: 100%;

  a {
    width: calc(50% - 8px);
    min-width: 140px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  ${({ theme }) => theme.mq('sm')} {
    max-width: 336px;
  }
`;

export const DownloadsContainer = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey04};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 4px 28px 0 rgb(0 0 0 / 12%);
  padding: ${({ theme }) => theme.spacing[50]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[40]};
  width: 100%;

  ${({ theme }) => theme.mq('sm')} {
    flex-direction: row;
    max-width: fit-content;
    align-items: center;
  }
`;

export const AppPromoQrCode = styled.div`
  width: 160px;
  height: 160px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const DesktopContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mq('md')} {
    display: block;
  }
`;

export const AboutAppLink = styled(Ui.TextLink)`
  font-size: 1rem;
  text-decoration: underline;
  display: block;
  color: ${({ theme }) => theme.color.brand};
  margin-top: ${({ theme }) => theme.spacing[20]};
`;
