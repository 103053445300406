import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { desaturate, lighten } from 'polished';
import ArrowNext from '@engineering/icons/arrow-next';
import { Theme } from '@silvertours/front-shared';

const desaturateAndLighten = (color: string) =>
  desaturate(0.6, lighten(0.545, color));

/**
 * These global styles are required by
 * [`react-day-picker`](https://react-day-picker.js.org/).
 * They are applied to the date picker on both search forms for
 * BM and CG, but also to the widgets under `/widget_search`.
 */
const GlobalStyles = ({
  theme,
  vertical,
}: {
  theme: Theme.Theme;
  vertical: boolean;
}) => css`
  /* stylelint-disable selector-class-pattern */
  .rdp-root {
    position: relative;
    outline: 0;
    color: ${theme?.color.text};
    background-color: ${theme?.color.white};
    font-family: ${theme?.fontFamily.default};
    user-select: none;

    ${vertical &&
    css`
      padding-block: ${theme?.spacing[40]};
      background:
        linear-gradient(${theme?.color.white} 30%, rgb(255 255 255 / 0%)),
        linear-gradient(rgb(255 255 255 / 0%), ${theme?.color.white} 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 20%), transparent),
        radial-gradient(
            farthest-side at 50% 100%,
            rgb(0 0 0 / 20%),
            transparent
          )
          0 100%;
      background-attachment: local, local, scroll, scroll;
      background-repeat: no-repeat;
      background-size:
        100% 40px,
        100% 40px,
        100% 14px,
        100% 14px;
      overflow-y: scroll;
    `}
  }

  .rdp-nav {
    position: absolute;
    width: 100%;
  }

  .rdp-button_previous,
  .rdp-button_next {
    position: absolute;
    top: 1.125rem;
    display: ${vertical ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    appearance: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .rdp-button_previous {
    left: 0.75rem;
  }

  .rdp-button_next {
    right: 0.75rem;
  }

  .rdp-months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ${theme.mq('xl')} {
      justify-content: space-around;
    }
  }

  .rdp-month {
    margin: 1.5rem 1rem 0;
  }

  .rdp-month_caption {
    margin: 0.25rem 0;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
  }

  .rdp-month_grid {
    border-spacing: 0;
    border-collapse: collapse;
  }

  .rdp-weekday {
    display: table-cell;
    padding: 0.5rem 0;
    color: ${theme?.color.grey03};
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
  }

  .rdp-day_button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    width: 2.5rem;
    height: 2rem;
    font: inherit;
    font-size: 1.125rem;
    text-align: center;
    vertical-align: middle;
    color: ${theme?.color.text};
    background: none;
    appearance: none;
    cursor: pointer;
  }

  .rdp-cell {
    padding: 0.5rem 0 0;
  }

  .rdp-day {
    padding: 0.5rem 0 0;

    &.rdp-hidden .rdp-day_button {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
    }

    &.rdp-outside .rdp-day_button {
      color: ${desaturateAndLighten(theme?.color.brand ?? '')};
      cursor: default;
    }

    &.rdp-disabled .rdp-day_button {
      color: ${theme?.color.grey03};
      cursor: default;
    }

    &.rdp-today:not(.rdp-selected) .rdp-day_button {
      margin: 0 0.25rem;
      width: 2rem;
      border: 1px solid ${theme?.color.grey01};
      border-radius: 100%;
    }

    /* selected pick-up/drop-off dates */
    &.rdp-selected {
      &:not(.rdp-disabled, .rdp-outside) {
        &.rdp-range_start .rdp-day_button {
          position: relative;
          color: ${theme?.color.white};
          background-image:
            radial-gradient(
              circle at center,
              ${theme?.color.brand},
              ${theme?.color.brand} 60%,
              transparent 65%
            ),
            linear-gradient(
              to right,
              transparent,
              transparent 49%,
              ${desaturateAndLighten(theme?.color.brand ?? '')} 50%
            );
        }

        &.rdp-range_end .rdp-day_button {
          position: relative;
          color: ${theme.color.white};
          background-image:
            radial-gradient(
              circle at center,
              ${theme?.color.brand},
              ${theme?.color.brand} 60%,
              transparent 65%
            ),
            linear-gradient(
              to left,
              transparent,
              transparent 49%,
              ${desaturateAndLighten(theme?.color.brand ?? '')} 50%
            );
        }

        &.rdp-range_start.rdp-range_end .rdp-day_button {
          position: relative;
          color: ${theme.color.white};
          background-image: radial-gradient(
            circle at center,
            ${theme?.color.brand},
            ${theme?.color.brand} 62%,
            transparent 63%
          );
        }
      }

      /* range between pick-up and drop-off */
      &:not(.rdp-range_start, .rdp-range_end, .rdp-outside) .rdp-day_button {
        color: ${theme?.color.text};
        background-color: ${desaturateAndLighten(theme?.color.brand ?? '')};
      }
    }
  }

  @media screen and (min-width: 300px) {
    .rdp-day {
      width: 40px;
    }

    .rdp-day.rdp-day_today:not(.rdp-day_selected) {
      margin-inline: 4px;
    }
  }

  /* large widgets only */
  @media screen and ((width: 800px) or (width: 970px)) and (height: 250px) {
    .rdp-month {
      margin-top: 0.125rem;
    }

    .rdp-nav_button {
      top: -0.125rem;
    }

    .rdp-head_cell {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .rdp-cell {
      padding-top: 4px;
    }
  }

  @media screen and (min-width: 1280px) {
    .rdp-months {
      justify-content: space-around;
    }
  }
  /* stylelint-enable selector-class-pattern */
`;

const Wrapper = styled.article`
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledHeader = styled.header`
  display: flex;
  padding: ${({ theme }) => theme.spacing[40]};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey03};
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
`;

const StyledFooter = styled.footer<{ vertical: boolean }>`
  padding: ${({ theme }) => theme.spacing[40]};
  border-top: ${({ theme, vertical }) =>
    vertical ? `1px solid ${theme.color.grey03}` : ''};
  text-align: ${({ vertical }) => (vertical ? '' : 'right')};
`;

const Arrow = styled(ArrowNext)`
  width: 1rem;
  height: 1rem;
  fill: currentcolor;
`;

export { GlobalStyles, Wrapper, StyledHeader, StyledFooter, Arrow };
