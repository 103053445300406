import { AppPromoContentModule } from '@silvertours/common-landingpages-view';
import {
  BodyBoldText,
  Theme,
  Ui,
  useMobileOS,
} from '@silvertours/front-shared';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useMedia } from 'react-use';
import { gtm } from '../analytics';
import AppIllustration from './app-illustration.png';
import StarRating from './star-rating.png';
import {
  PromoContainer,
  ContentGrid,
  IntroSection,
  Logo,
  Title,
  FeaturesList,
  FeatureItem,
  FeatureDot,
  StoreLinksContainer,
  IllustrationWrapper,
  ContentSection,
  DownloadsContainer,
  AboutAppLink,
} from './AppPromoModule.styles';
import { AppDownloadQRCode } from './AppDownloadQRCode';

export const AppPromoModule = ({
  badge: { text: badgeText } = { text: '' },
  aboutText = '',
  heading: { text: title } = { text: '' },
  illustrationTextDesktop,
  illustrationTextMobile,
  features: usps = [],
}: AppPromoContentModule) => {
  const t = useTranslations('features.mobileAppPromotion');
  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('sm')}px)`,
    true,
  );
  const os = useMobileOS();
  const illustrationText = isMobile
    ? illustrationTextMobile
    : illustrationTextDesktop;

  return (
    <Ui.Wave type="scalable3" fullWidth backgroundStyle="mintAlt">
      <PromoContainer>
        <ContentGrid>
          <ContentSection>
            <Ui.Badge text={badgeText} type="orange" />
            <IntroSection>
              <Logo />
              <div>
                <Title>{title}</Title>

                <Image
                  src={StarRating}
                  alt=""
                  width="158"
                  style={{ objectFit: 'contain' }}
                />

                <FeaturesList>
                  {usps.map(usp => (
                    <FeatureItem key={usp}>
                      <FeatureDot />
                      {usp}
                    </FeatureItem>
                  ))}
                </FeaturesList>
              </div>
            </IntroSection>
          </ContentSection>
          <IllustrationWrapper>
            <Image
              src={AppIllustration}
              alt=""
              width="210"
              style={{ objectFit: 'contain' }}
            />
            <DownloadsContainer>
              {!isMobile && <AppDownloadQRCode />}
              <div>
                <BodyBoldText inline>{illustrationText}</BodyBoldText>
                <br />
                <AboutAppLink href="app.html" text={aboutText} />
              </div>
              {isMobile && (
                <StoreLinksContainer>
                  {(os === 'ios' || os === 'unknown') && (
                    <Ui.AppStoreLink
                      id="app-promo-app-store-anchor"
                      href={t('appStoreLink')}
                      onClick={gtm.trackIosAppLinkClicked}
                    />
                  )}
                  {(os === 'android' || os === 'unknown') && (
                    <Ui.PlayStoreLink
                      id="app-promo-play-store-anchor"
                      href={t('playStoreLink')}
                      onClick={gtm.trackAndroidAppLinkClicked}
                    />
                  )}
                </StoreLinksContainer>
              )}
            </DownloadsContainer>
          </IllustrationWrapper>
        </ContentGrid>
      </PromoContainer>
    </Ui.Wave>
  );
};
